import React from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { Button, DosDont, Hero, SectionHeader } from "../components/common";

import InfinityDoc from "../assets/images/vectors/infinity-doc.svg";

import "./license.css";
import { getStaticSEOImage } from "../helpers";

function CookiePolicyPage({ location }) {
  return (
    <Layout>
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title="License"
        image={getStaticSEOImage("license")}
        path={location?.pathname}
      />
      <section className="my-24">
        <Hero
          subTitle="Whoa!"
          title={
            <>
              Lifetime License
              <br />
              Buy Once, Use Unlimited
            </>
          }
        />
      </section>
      <section className="mb-32">
        <div className="dev-vg-license__container m-auto content">
          <p className="text-ui-gray-plus-4 font-normal text-lg leading-8 tracking-tightsy mb-16">
            This license is an agreement between You and the Vackground Ltd.
            When you make a purchase/free-download with Vackground, you will get
            a “license” to use in any personal and commercial projects. They are
            royalty free, which means you will only pay once but use multiple
            (unlimited) times.
            <br />
            <br />
            <strong>
              A “license” means you have the permission to use the
              purchased/free product. Vackground is still the owner of the
              product.
            </strong>
            <br />
            <br />
            Your license allows you to use the product to create unlimited end
            products. Create for yourself, your client, or your company. The end
            product may be sold, licensed, sub-licensed or freely distributed.
            An end product is something that incorporates the product as well as
            other things, so that it is larger in scope and different in nature
            than the original product. For example:
          </p>
          <div className="relative flex flex-col gap-4 ml-16 lg:ml-0">
            {[
              {
                info: true,
                content:
                  "The product is a background image and the end product is a book cover, website, poster, ad banner, social media campaign, and so on.",
              },
              {
                info: true,
                content:
                  "The product is a set of graphic icons/objects and the end product is a mobile app, website, mug, or t-shirt for sell.",
              },
              {
                info: true,
                content:
                  "The product is a repeatable surface pattern and the end product is a bed cover, curtain,  interior wallpaper, phone cover, wrapping paper, and so on.",
              },
              {
                info: true,
                content:
                  "The product is an illustration and the end product is a website, mobile app, poster, brochure, presentation, and so on.",
              },
            ].map((item, index) => (
              <DosDont key={index} no={item.no} yes={item.yes} info={item.info}>
                {item.content}
              </DosDont>
            ))}
          </div>
          <h2 className="text-ui-gray-plus-4 font-bold lg:text-4xl md:text-3.5xl text-2.25xl md:leading-11 leading-9 mb-8 lg:tracking-tightty md:tracking-tighteer tracking-tightie">
            You Can...
          </h2>
          <div className="relative flex flex-col gap-4 ml-16 lg:ml-0">
            {[
              {
                yes: true,
                content: `Use both free and premium products for personal & commercial projects. Premium products are attribution-free but free products requires attribution. See the attribution section below for more information.`,
              },
              {
                yes: true,
                content: `Create unlimited digital end products for personal & commercial purpose (i.e. download a background to use it on a website or social media post, download a graphic object to use it on presentation, ad banner etc)`,
              },
              {
                yes: true,
                content: `Create unlimited digital end products for sell by adding licensed graphic(s) into new works. You must create distinct new works along with the licensed graphic(s) where the end product would not compete with the licensed graphic(s).`,
              },
              {
                yes: true,
                content: `Print licensed graphic(s) on unlimited physical end products for sell (i.e. download a design and print it on thousand/unlimited bed covers)`,
              },
              {
                yes: true,
                content: `Print licensed graphic(s) as it is or with modifications for your physical end products (we encourage you to modify to make something unique)`,
              },
              {
                yes: true,
                content: `Use same licensed graphic(s) to print on multiple physical products, unlimited copies for each (i.e same design on unlimited number of mugs, curtains, book covers, etc)`,
              },
            ].map((item, index) => (
              <DosDont key={index} no={item.no} yes={item.yes}>
                {item.content}
              </DosDont>
            ))}
          </div>
          <h2 className="text-ui-gray-plus-4 font-bold lg:text-4xl md:text-3.5xl text-2.25xl md:leading-11 leading-9 mb-8 lg:tracking-tightty md:tracking-tighteer tracking-tightie">
            You Can Not...
          </h2>
          <div className="relative flex flex-col gap-4 ml-16 lg:ml-0">
            {[
              {
                no: true,
                content: `Re-distribute the licensed graphic(s) as stock image or video, as an additional attachment within a tool/template/end product’s source file.`,
              },
              {
                no: true,
                content: `Permit the end user of the end product to extract the licensed graphic(s) to its original form and use it seperately from the end product.`,
              },
              {
                no: true,
                content: `Resell, redistribute, lease, license, sub-license the Vackground product by any manner of means.`,
              },
              {
                no: true,
                content: `Include licensed graphic(s) into a software, app, or any kind of program that allows your user to create design with “mix and match”, “drag and drop” and/or “click to generate” system.`,
              },
            ].map((item, index) => (
              <DosDont key={index} no={item.no}>
                {item.content}
              </DosDont>
            ))}
          </div>
          <h2 className="text-ui-gray-plus-4 font-bold lg:text-4xl md:text-3.5xl text-2.25xl md:leading-11 leading-9 mb-8 lg:tracking-tightty md:tracking-tighteer tracking-tightie">
            Free Demo
          </h2>
          <p>
            Some premium products have free demo files to see inside of the main
            product. A demo file allows you to check the quality and other
            things before making a purchase. Beside that, use it for testing,
            internal use, and/or learning purpose. For example:
          </p>
          <br />
          <div className="relative flex flex-col gap-4 ml-16 lg:ml-0">
            {[
              {
                yes: true,
                content: `Use as a placeholder image of your design. Show it to your client or team lead for approval. It they approve, then purchase the main product.`,
              },
              {
                yes: true,
                content: `If you are a teacher or course creator, demonstrate how to use the demo files to create something. But show your students from where you got those demo files.`,
              },
              {
                yes: true,
                content: `And if you are a student, use demo files for your learning purpose.`,
              },
              {
                no: true,
                content: `Do not use, upload, or distribute demo files publicly with or without modification.`,
              },
            ].map((item, index) => (
              <DosDont key={index} no={item.no} yes={item.yes}>
                {item.content}
              </DosDont>
            ))}
          </div>
          <h2 className="text-ui-gray-plus-4 font-bold lg:text-4xl md:text-3.5xl text-2.25xl md:leading-11 leading-9 mb-8 lg:tracking-tightty md:tracking-tighteer tracking-tightie">
            Attribution
          </h2>
          <p className="text-ui-gray-plus-4 font-normal text-lg leading-8 tracking-tightsy mb-16">
            Premium product does not require attribution. You have paid for it,
            so you don’t have to provide attribution. However, we appreciate if
            you show some generosity by giving a credit link or shoutout on
            social media.
            <br />
            <br />
            But free product requires attribution. As like as our premium
            products, we do countless hours of hard work for free products.
            Please support us by giving an attribution wherever you use our free
            graphic(s).
            <br />
            <br />
            If you don’t want to provide attribution for a free product, please
            support us by purchasing any of our premium product. If you use 2,
            3, or multiple free products, support us by purchasing 2, 3, or
            multiple premium products respectively.
          </p>
          <h2 className="text-ui-gray-plus-4 font-bold lg:text-4xl md:text-3.5xl text-2.25xl md:leading-11 leading-9 mb-8 lg:tracking-tightty md:tracking-tighteer tracking-tightie">
            For 1 (One) Person Only
          </h2>
          <p className="text-ui-gray-plus-4 font-normal text-lg leading-8 tracking-tightsy mb-16">
            The license terms, requirements and agreements above is only for 1
            (one) person. You can buy a license for yourself, for your employee,
            or for anyone. If you buy a license for someone, you must not use
            the product. Please handover the files to that person.
            <br />
            <br />
            And if you need multiple license for multiple person, or a team
            license for your entire house, please contact us so we can provide a
            custom license for you.
          </p>
          <h2 className="text-ui-gray-plus-4 font-bold lg:text-4xl md:text-3.5xl text-2.25xl md:leading-11 leading-9 mb-8 lg:tracking-tightty md:tracking-tighteer tracking-tightie">
            Custom License
          </h2>
          <div className="relative flex flex-col gap-4 ml-16 lg:ml-0">
            {[
              {
                mail: true,
                content: `If you need a big license for your whole team, contact us to get a custom, discounted price for one or more products.`,
              },
              {
                mail: true,
                content: `If you need special license for your software, app, or any kind of program that allows your user to create design with “mix and match”, “drag and drop” and/or “click to generate” system, please contact us.`,
              },
              {
                mail: true,
                content: `Other than the above, contact us for a custom license if you need any specific requirement or use case.`,
              },
            ].map((item, index) => (
              <DosDont key={index} mail={item.mail}>
                {item.content}
              </DosDont>
            ))}
            <h2 className="text-ui-gray-plus-4 font-bold lg:text-4xl md:text-3.5xl text-2.25xl md:leading-11 leading-9 mb-8 lg:tracking-tightty md:tracking-tighteer tracking-tightie">
              Rights
            </h2>
            <p className="text-ui-gray-plus-4 font-normal text-lg leading-8 tracking-tightsy mb-16">
              Vackground reserves the right, in its sole discretion, to modify
              or replace the license terms at any time. The updated license will
              be effective immediately.
              <br />
              <br />
              Your old purchases and downloads will remain tied to the old
              license of that time. You have nothing to worry if the license
              changes time to time. As a good practice, Vackground encourages
              you to check the license each time before making a purchase.
            </p>
          </div>
        </div>
      </section>
      <section className="mb-24">
        <div className="mb-8">
          <SectionHeader
            art
            artVector={InfinityDoc}
            title="Have Questions or Concerns Regarding License?"
          />
        </div>
        <div className="flex items-center justify-center">
          <Button to="/contact" hasMoustache icon="vg-mail" text="Contact Us" />
        </div>
      </section>
    </Layout>
  );
}

CookiePolicyPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default CookiePolicyPage;
